import '@/installCompositionApi.js'
import {HelpCaseFrontend} from '@/models/ResponseTypes'
import {computed, ref} from '@vue/composition-api'
import HelpCaseService from '@/services/HelpCaseService'

interface StateInterface {
    helpCases: HelpCaseFrontend[];
}

const state = ref<StateInterface>({
    helpCases: [],
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function useHelpCase() {
    const getHelpCases = async (siteId: number): Promise<HelpCaseFrontend[]> => {
        const response = await HelpCaseService.getHelpCases(siteId)
        state.value.helpCases = response
        return response
    }

    return {
        helpCases: computed(() => state.value.helpCases),
        getHelpCases,
    }
}
