var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('pui-navigation-toolbar',{attrs:{"link-items":_vm.linkItems,"structure-select":{
            config: {
                options: _vm.availableSites.map(function (site) {
                    return {
                        text: site.displayName,
                        id: site.siteId.toString(),
                    }
                })
            },
            selectedId: _vm.selectedSiteId.toString(),
        },"action-items":_vm.actionsList},on:{"clicked:tab":_vm.handleNavigation,"clicked:action":_vm.openUploadModal,"selected:structure-select-id":_vm.onSelectSite}}),_c('upload-dialog',{attrs:{"open":_vm.isHelpCaseModalOpen},on:{"close":_vm.closeUploadModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }