import {
    PostHelpCaseFileResponse,
    HelpCaseLastUpdatedFileInfoResponse,
    HelpCaseFrontend
} from '@/models/ResponseTypes'
import {AxiosRequestConfig} from 'axios'
import {sdk} from '@/utils/sdk'

export class HelpCaseService {

    public async getHelpCases(siteId: number, config?: AxiosRequestConfig): Promise<HelpCaseFrontend[]> {
        return (await sdk?.backend.request.api.get<{result: HelpCaseFrontend[]}>(
            `helpcase/${siteId}`,
            config
        )).data.result
    }

    public async getLastUpdatedFileInfo(config?: AxiosRequestConfig): Promise<HelpCaseLastUpdatedFileInfoResponse> {
        return (await sdk?.backend.request.api.get<{result: HelpCaseLastUpdatedFileInfoResponse}>(
            `helpcase/lastupdated`,
            config
        )).data.result
    }

    public async postHelpCaseFile(formData: FormData, siteId: number, config?: AxiosRequestConfig): Promise<PostHelpCaseFileResponse> {
        return (await sdk?.backend.request.api.post<{result: PostHelpCaseFileResponse}>(
            `helpcase/${siteId}`,
            formData,
            {
                ...config,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        )).data.result
    }
}


export default new HelpCaseService()
