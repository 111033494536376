import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import useCases from './use-cases'

Vue.use(Vuex)

const applicationState: {
    isLoading: boolean;
} = {
    isLoading: false,
}

export default new Vuex.Store({
    state: applicationState,
    mutations: {
        loading(state): void {
            state.isLoading = !state.isLoading
        },
    },
    actions: {},
    modules: {
        user,
        useCases
    },
    getters: {
        isLoading(state): boolean {
            return state.isLoading
        },
    },
})
