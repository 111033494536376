import {PostBackfillDataResponse} from '@/models/ResponseTypes'
import {AxiosRequestConfig} from 'axios'
import { sdk } from '@/utils/sdk'

export class BackfillDataService {

    public async postBackfillData(formData: FormData, siteId: number, config?: AxiosRequestConfig): Promise<PostBackfillDataResponse> {
        return (await sdk?.backend.request.api.post<{result: PostBackfillDataResponse}>(
            `backfill/${siteId}`, formData,
            {
                ...config,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        )).data.result
    }
}


export default new BackfillDataService()
