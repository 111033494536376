import { User } from '@/models/users-response'
import { AxiosRequestConfig } from 'axios'
import { sdk } from '@/utils/sdk'

export class UsersService {
    endpoint = 'users'

    public async getSiteUsers(siteId: number): Promise<User[]> {
        return (await sdk?.backend.request.api.get<{result: {users: User[]}}>(
            `${this.endpoint}/${siteId}`
        )).data.result.users
    }

    public async getSiteInvestigators(siteId: number): Promise<User[]> {
        return (await sdk?.backend.request.api.get<{result: {users: User[]}}>(
            `${this.endpoint}/investigators/${siteId}`
        )).data.result.users
    }


    public async filterUsersByName(term: string, size?: number): Promise<User[]> {
        const config: AxiosRequestConfig = {
            params: {
                term: term ?? '',
                size: size ?? 20
            }
        }
        return (await sdk?.backend.request.api.get<{result: {users: User[]}}>(
            `${this.endpoint}/search`,
            config
        )).data.result.users
    }
}

export const usersService = new UsersService()

