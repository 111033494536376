<template>
    <div class="help-case-upload-dialog-content">
        <pui-form
            v-if="!loading"
            @reset="onReset"
            @submit.prevent="onSubmit"
        >
            <div class="flex mt-8">
                <pui-icon
                    icon-name="warning"
                    icon-color="#fda428"
                />
                <div class="pl-2">
                    {{ $t('uploadDialog.backfillDataWarning', { selectedSiteName: selectedSiteName }) }}
                </div>
            </div>
            <div class="grid grid-cols-2">
                <pui-form-group
                    :label="$t('uploadDialog.chooseBackfillUploadFile')"
                    style="height: 92px"
                >
                    <pui-file-upload
                        :on-change-callback="onChangeCallback"
                        :label="$t('common.fileUpload')"
                        @puifileupload:removed="onRemove"
                    >
                        <pui-icon
                            slot="left"
                            icon-name="icon-add-attachment"
                        />
                    </pui-file-upload>
                </pui-form-group>

                <div
                    v-if="showResponseIcon"
                    class="flex items-end justify-start"
                >
                    <pui-icon
                        v-if="uploadSuccessful"
                        icon-color="green"
                        icon-name="check"
                        size="5rem"
                    />
                    <pui-icon
                        v-else
                        icon-color="red"
                        icon-name="close"
                        size="5rem"
                    />
                </div>
            </div>


            <div class="flex flex-row justify-end mt-16">
                <pui-button
                    class="mr-2"
                    state="secondary"
                    type="reset"
                >
                    {{ $t('common.cancel') }}
                </pui-button>
                <pui-button
                    :disabled="!formFile"
                    state="primary"
                    type="submit"
                >
                    {{ $t('common.submit') }}
                </pui-button>
            </div>
        </pui-form>
        <pui-loader-spinner
            v-else
            :message="$t('uploadDialog.uploadPendingMessage')"
            min-height="92px"
            size="60px"
            :title="$t('common.pleaseWait')"
        />
    </div>
</template>
<script lang="ts">
import {defineComponent, ref} from '@vue/composition-api'
import BackfillDataService from '@/services/BackfillDataService'
import useSiteSettings from '@/store/composables/useSiteSettings'
export default defineComponent({
    setup(props, {emit}) {
        const formFile = ref<File | null>(null)
        const { selectedSiteId, selectedSiteName } = useSiteSettings()

        const onReset = (): void => {
            formFile.value = null
            emit('close')
        }
        const onRemove = (): void => {
            formFile.value = null
        }

        const loading = ref(false)

        const showResponseIcon = ref(false)

        const uploadSuccessful = ref(false)

        const onSubmit = async (): Promise<void> => {
            const formData = new FormData()
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            formData.append('ExcelFileToUpload', formFile?.value!)
            loading.value = true
            try {
                const fileResponse = await BackfillDataService.postBackfillData(formData, selectedSiteId.value)
                uploadSuccessful.value = fileResponse.success
            } catch (e) {
                uploadSuccessful.value = false
            } finally {
                showResponseIcon.value = true
                loading.value = false
                formFile.value = null
            }
        }

        const onChangeCallback = (file: File): void => {
            formFile.value = file
        }


        return {onReset, formFile, onChangeCallback, onRemove, onSubmit, loading, showResponseIcon, uploadSuccessful, selectedSiteName}
    }
})
</script>
