<template>
    <div class="grid grid-flow-row auto-rows-max gap-2">
        <div>
            {{ user.name }}
        </div>
        <div>
            {{ user.email }}
        </div>

        <div class="mt-8">
            <pui-link
                icon="duplicate"
                :title="$t('profile.copyAccessToken')"
                @click="copyToken()"
            />
        </div>

        <div>
            <pui-link
                :href="swaggerURL"
                target="_blank"
                icon="report"
                :title="$t('profile.openSwagger')"
            />
        </div>

        <div class="mt-6">
            {{ `${$t('profile.version')} ${version}` }}
        </div>
        <div class="pb-4">
            {{ $t('profile.apiUrl') }}
            <a
                :href="apiUrl"
            >
                {{ apiUrl }}
            </a>
        </div>

        <pui-button
            state="secondary"
            icon="report"
            class="profile-action-button"
            @click="sendFeedback()"
        >
            {{ $t('sendFeedback') }}
        </pui-button>

        <pui-button
            state="secondary"
            icon="warning"
            class="profile-action-button profile-action-button--important"
            @click="reportIncident()"
        >
            {{ $t('reportIncident') }}
        </pui-button>

        <pui-button
            icon="power"
            class="profile-action-button"
            @click="logOut()"
        >
            {{ $t('signOut') }}
        </pui-button>

        <input
            id="copyText"
            type="text"
            class="copy-text"
        >
    </div>
</template>
<script lang="ts">
import {defineComponent, PropType} from '@vue/composition-api'
import {EventBus} from '@/utils'
import { sdk } from '@/utils/sdk'

export default defineComponent({
    props: {
        user: {
            type: Object as PropType<{ name: string; email: string }>,
            required: true,
        },
    },

    setup() {
        const version: string | undefined = process.env.VUE_APP_VERSION
        const apiUrl: string | undefined = process.env.VUE_APP_API_BASE_URL
        const swaggerURL: string | undefined = process.env.VUE_APP_API_SWAGGER_URL
        const scope = process.env.VUE_APP_FRACAS_SCOPE
        const appStoreUrl: string | undefined = process.env.VUE_APP_APPSTORE_UI_URL
        const useCaseId: string | undefined = process.env.VUE_APP_USE_CASE_ID

        const logOut = (): void => {
            sdk?.auth.logout()
        }

        const sendFeedback = (): void => {
            window.open(`${appStoreUrl}/contact-page/${useCaseId}?isFeedback=true`, '_blank')
        }

        const reportIncident = (): void => {
            window.open(`${appStoreUrl}/incident-page/${useCaseId}`, '_blank')
        }

        const copyToken = async (): Promise<void> => {
            const textElement = document.getElementById('copyText')
            if (textElement instanceof HTMLInputElement) {
                const token = await sdk?.auth.getToken(scope as string)
                textElement.value = ''
                textElement.value = `Bearer ${token}`
                textElement.select()
                document.execCommand('copy')
                EventBus.$emit('copiedToClipboard')
            } else {
                throw new Error('Copy text element is undefined')
            }
        }

        return {
            logOut,
            copyToken,
            reportIncident,
            sendFeedback,
            version,
            apiUrl,
            swaggerURL
        }
    }
})
</script>

<style scoped lang="less">
@import '../styles/variables.less';
.profile-action-button {
    text-transform: none;
    width: 100%;
    border-radius: 0;
    margin-bottom: 2px;
    height: 3.6rem;

    &--important {
        border: 1px solid @contextual-red !important;
        color: @contextual-red !important;
        &:hover {
            color: @dark-red !important;
            border: 1px solid @dark-red !important;
        }
    }
}

.copy-text {
    position: fixed;
    top: -99999rem;
}
</style>
