declare global {
    interface Date {
        getWeek(): number;
    }
}

const WEEK_START_CORRECTION = 1
const DAYS_IN_WEEK = 7
const MS_IN_DAY = 24 * 60 * 60 * 1000

Date.prototype.getWeek = function(): number{
    const date = new Date(this)
    date.setUTCDate(date.getUTCDate() + WEEK_START_CORRECTION - (date.getUTCDay() || DAYS_IN_WEEK))
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
    const weekNumber = Math.ceil((((date.getTime() - yearStart.getTime()) / MS_IN_DAY) + 1) / DAYS_IN_WEEK)
    return weekNumber
}

export const getWeekNumber = (actionDueDate: string | null | undefined): number => {
    if (!actionDueDate) {
        throw new Error('Invalid due date')
    }

    const date = new Date(actionDueDate)
    if (isNaN(date.getTime())) {
        throw new Error('Invalid date format')
    }
    
    return date.getWeek()
}