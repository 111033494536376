import {IncidentHistoryFrontend, IncidentHistoryResponse} from '@/models/ResponseTypes'
import useUser from '@/store/composables/useUser'

export const transformToFrontendFormat = (incident: IncidentHistoryResponse): IncidentHistoryFrontend => {
    return {
        ...incident,
        notificationNumber: incident.notificationNumber ?? '',
        investigationStartDateString: incident.investigationStart ? new Date(incident.investigationStart).toLocaleDateString('en-GB') : undefined,
        investigationStartDateStringDialog: incident.investigationStart ? incident.investigationStart.toString().substring(0, 10) : undefined,
        investigationEndDateString: incident.investigationEnd ? new Date(incident.investigationEnd).toLocaleDateString('en-GB') : undefined,
        investigationEndDateStringDialog: incident.investigationEnd ? incident.investigationEnd.toString().substring(0, 10) : undefined,
        investigator: incident.investigatorKid? useUser().replaceKidByUsername(incident.investigatorKid) : undefined,
        costImpactString: incident.costImpact !== undefined ? incident.costImpact.toString() : undefined,
        residualRiskString: incident.residualRisk !== undefined ? incident.residualRisk.toString() : undefined,
    }
}
