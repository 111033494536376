<template>
    <div
        v-if="isAppReady"
        id="app"
    >
        <adam-header v-if="!isMaintenancePage" />
        <div v-if="hasAppReadAccess && !isMaintenancePage">
            <navigation-bar />
            <div
                class="pt-4 overflow-y-auto"
                style="height: calc(100vh - 9.6rem)"
            >
                <router-view />
            </div>
        </div>
        <Forbidden v-else-if="!isMaintenancePage" />
        <router-view v-else />
    </div>
</template>

<script lang="ts">
import './styles/main.less'
import NavigationBar from '@/components/NavigationBar.vue'
import AdamHeader from '@/components/AdamHeader.vue'
import Forbidden from '@/views/Forbidden.vue'
import { Component, Vue } from 'vue-property-decorator'
import { ACTIONS } from './store/types/actions-and-mutations'
import { mapGetters } from 'vuex'
import { getTranslationManager } from '@/utils/translation-manager'
import { sdk } from '@/utils/sdk'

@Component({
    name: 'app',
    components: {
        AdamHeader,
        NavigationBar,
        Forbidden,
    },
    computed: mapGetters({
        hasAppReadAccess: 'hasAppReadAccess'
    }),
})
export default class App extends Vue {
    private isAppReady = false

    private useCaseId = Number(process.env.VUE_APP_USE_CASE_ID)
    private scope = process.env.VUE_APP_FRACAS_SCOPE ?? ''
    private baseUrl = process.env.VUE_APP_API_BASE_URL ?? ''
    private hasAppReadAccess!: boolean

    private async created(): Promise<void> {
        await this.loadAuth()
    }

    private async loadAuth(): Promise<void> {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID)
        await sdk?.core.userAccessManagement.setPermissions(useCaseId)

        await Promise.all([
            this.$store.dispatch(ACTIONS.SET_USER_PROFILE_DATA),
            this.$store.dispatch(ACTIONS.LOAD_USE_CASES),
            getTranslationManager().fetchInitialData({
                $http: {
                    $core: {
                        translation: {
                            request: {
                                api: sdk.core.translation.request.api
                            }
                        }
                    } as any
                } as any
            })
        ])

        this.isAppReady = true
    }

    private get isMaintenancePage(): boolean {
        return this.$route.name === 'Maintenance'
    }
}
</script>
