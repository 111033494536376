import '@/installCompositionApi.js'
import {computed, ref} from '@vue/composition-api'
import {Investigator} from '@/models/ResponseTypes'
import {usersService} from '@/services/UsersService'
import {User} from '@/models/users-response'
import {UserProfile} from '@/models/UserProfile'

interface StateInterface {
    users: User[];
    investigators : User[];
    userProfile: UserProfile;
    usersFilteredByName: User[];
    isFilteringUsers: boolean;
}

const state = ref<StateInterface>({
    users: [],
    investigators : [],
    userProfile: new UserProfile(),
    usersFilteredByName: [],
    isFilteringUsers: false
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function useUser() {

    const getSiteUsers = async (siteId: number): Promise<User[]> => {
        const users = await usersService.getSiteUsers(siteId)

        state.value.users = users

        return state.value.users
    }

    const getSiteInvestigators = async (siteId: number): Promise<User[]> => {
        const users = await usersService.getSiteInvestigators(siteId)

        state.value.investigators = users

        return state.value.investigators
    }

    const replaceKidByUsername = (investigatorKid: string): string => {
        const investigator = state.value.users?.find(u => u.kid === investigatorKid)
        if(investigator?.name) {
            return investigator.name
        }
        return investigatorKid
    }

    const getUserByMail = (userMail: string): Investigator => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        return state.value.users?.find(u => u.email === userMail)!
    }

    const getUserByKid = (userKid: string): Investigator => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        return state.value.users?.find(u => u.kid === userKid)!
    }

    const getUsersByName = async (term: string, size?: number): Promise<User[]> => {
        state.value.isFilteringUsers = true
        const filteredUsers = await usersService.filterUsersByName(term, size)

        state.value.usersFilteredByName = filteredUsers
        state.value.isFilteringUsers = false

        return state.value.usersFilteredByName
    }

    const getUsernameByKidWithDefault = (userKid: string | null | undefined, defaultString = ''): string => {
        if(userKid == null)
        {
            return defaultString
        }

        const user = getUserByKid(userKid)
        const username = user ? user.name ?? defaultString : userKid + ' (unregistered)'
        return username
    }

    const getUsernameByKid = (userKid: string | null | undefined): string => {
        return getUsernameByKidWithDefault(userKid)
    }

    const setCurrentUser = (user: UserProfile): void => {
        state.value.userProfile = user
    }

    return {
        setCurrentUser,
        getSiteInvestigators,
        getSiteUsers,
        getUserByMail,
        getUserByKid,
        getUsernameByKid,
        getUsernameByKidWithDefault,
        replaceKidByUsername,
        getUsersByName,
        users: computed(() => state.value.users),
        investigators: computed(() => state.value.investigators),
        usersFilteredByName: computed(() => state.value.usersFilteredByName),
        isFilteringUsers: computed(() => state.value.isFilteringUsers),
        currentUser: computed(() => state.value.userProfile)
    }
}
