import { IncidentFrontend, IncidentResponse } from '@/models/ResponseTypes'
import { IncidentRequest } from '@/models/RequestTypes'
import useUser from '@/store/composables/useUser'

export const transformToFrontendFormat = (incident: IncidentResponse): IncidentFrontend => {
    const piData = incident.piData ? {
        ...incident.piData,
        eohDiff: incident.piData.eohDiff?.toString(),
        unavailableEnergy: incident.piData.unavailableEnergy?.toString()
    } : {
        piId: incident.piData,
        unavailableEnergy: incident.unavailableEnergy?.toString(),
        eohDiff: incident.penaltyValue?.toString()
    }

    return {
        ...incident,

        notificationType: incident.notificationType ? incident.notificationType : null,
        notificationTitle: incident.notificationTitle ? incident.notificationTitle : null,
        notificationEventDescription: incident.notificationEventDescription ? incident.notificationEventDescription : null,
        functionalLocation: incident.functionalLocation ? incident.functionalLocation : null,
        plannerGroup: incident.plannerGroup ? incident.plannerGroup : null,
        faultText: incident.faultText ? incident.faultText : null,
        problemStatement: incident.problemStatement ? incident.problemStatement : null,
        notificationStatus: incident.notificationStatus ? incident.notificationStatus : null,
        systemStatusEN: incident.systemStatusEN ? incident.systemStatusEN : null,
        eventType: incident.eventType ? incident.eventType : null,
        conditionBefore: incident.conditionBefore ? incident.conditionBefore : null,
        investigationStartDateString: incident?.investigationStart?.toString().substring(0, 10),
        investigationEndDateString: incident?.investigationEnd?.toString().substring(0, 10),

        eventStartDateString: incident?.eventStart?.toString().substring(0, 10),
        eventStartTimeString: incident?.eventStart?.toString().substring(11, 16),

        environmental: incident.environmental ? incident.environmental : null,
        safety: incident.safety ? incident.safety : null,
        componentOther: incident.componentOther ? incident.componentOther : null,

        reviewDateString: incident.reviewDate?.toString().substring(0, 10),
        riskAssessmentDateString: incident.riskAssessmentDate?.toString().substring(0, 10),

        helpCaseData: incident.helpCaseData ? {
            ...incident.helpCaseData,
            caseId: incident.helpCaseData.caseId,
            question: incident.helpCaseData.question,
            caseNotes: incident.helpCaseData.caseNotes,
        } : { caseId: null, question: null, caseNotes: null },

        investigator: incident.investigatorKid ? {
            name: useUser().getUserByKid(incident.investigatorKid)?.name,
            email: useUser().getUserByKid(incident.investigatorKid)?.email,
            kid: incident?.investigatorKid
        } : { name: null, email: null, kid: null },

        piData,
        penaltyValue: piData.eohDiff,

        costImpact: incident.costImpact !== undefined ? incident.costImpact.toString() : null,
        severityTag: incident.severityTag ? incident.severityTag : 'No Severity',

        investigationEnd: incident.investigationEnd ? new Date(incident.investigationEnd.toString().substring(0, 10)) : null,
        synergiLifeCaseNo: incident.synergiLifeCaseNo !== undefined ? incident.synergiLifeCaseNo.toString() : '',
        ptRiskId: incident.ptRiskId ? incident.ptRiskId : null,
        causeDescription: incident.causeDescription ? incident.causeDescription : null,
        immediateAction: incident.immediateAction ? incident.immediateAction : null,
        investigatorComment: incident.investigatorComment ? incident.investigatorComment : null,

        isProcessSafetyRelated: incident.isProcessSafetyRelated ? incident.isProcessSafetyRelated : false,

        residualSeverityString: incident.residualSeverity !== undefined ? incident.residualSeverity.toString() : null,
        residualProbabilityString: incident.residualProbability !== undefined ? incident.residualProbability.toString() : null,
        residualRiskString: incident.residualRisk !== undefined ? incident.residualRisk.toString() : null,

        correctiveNotification: incident.correctiveNotification ? {
            ...incident.correctiveNotification,
            incidentId: incident.correctiveNotification.incidentId,
            notificationNumber: incident.correctiveNotification.notificationNumber,
            notificationStatus: incident.correctiveNotification.notificationStatus,
            notificationEventDescription: incident.correctiveNotification.notificationEventDescription,
            systemStatusEN: incident.correctiveNotification.systemStatusEN,
            notificationTitle: incident.correctiveNotification.notificationTitle,
            causeDescription: incident.correctiveNotification.causeDescription,
        } : { notificationNumber: '', notificationStatus: '', notificationEventDescription: '', systemStatusEN: '', notificationTitle: '', causeDescription: '' },
    } as IncidentFrontend
}

export const transformToBackendFormat = (incident: IncidentFrontend): IncidentRequest => {
    const piData = incident.piData?.piId ? incident.piData : null
    const penaltyValue = piData ? undefined : incident.penaltyValue

    return {
        ...incident,
        eventStart: incident.eventStartDateString ? incident.eventStartDateString + 'T' + incident.eventStartTimeString + ':00' : null,
        synergiLifeCaseNo: incident.synergiLifeCaseNo !== '' ? incident.synergiLifeCaseNo : null,
        investigationStart: incident.investigationStartDateString ? new Date(incident.investigationStartDateString) : null,
        investigationEnd: incident.investigationEndDateString ? new Date(incident.investigationEndDateString) : null,

        reviewDate: incident.reviewDateString ? new Date(incident.reviewDateString) : null,
        riskAssessmentDate: incident.riskAssessmentDateString ? new Date(incident.riskAssessmentDateString) : null,

        investigatorKid: incident.investigator?.kid,
        investigatorName: incident.investigator?.name,
        investigatorEmail: incident.investigator?.email,

        correctiveNotification: incident.correctiveNotification ?? null,
        correctiveNotificationIncidentId: incident.correctiveNotification?.incidentId ?? incident.correctiveNotificationIncidentId ?? null,
        helpCaseData: incident.helpCaseData?.caseId ? incident.helpCaseData : null,

        costImpact: incident.costImpact ? parseInt(incident.costImpact) : null,
        unavailableEnergy: incident.piData?.unavailableEnergy ? parseFloat(incident.piData.unavailableEnergy) : null,
        penaltyValue: penaltyValue === undefined ? null : parseFloat(penaltyValue),
        piData,

        residualSeverity: incident.residualSeverityString ? parseFloat(incident.residualSeverityString) : null,
        residualProbability: incident.residualProbabilityString ? parseInt(incident.residualProbabilityString) : null,
        residualRisk: incident.residualRiskString ? parseFloat(incident.residualRiskString) : null,

    } as IncidentRequest
}
