export enum IncidentDataSource {
    SAP = 'SAP A1',
    MODE = 'MODE',
    MANUAL = 'Operational Incident Report'
}

export function generateIncidentDataSourceOptions(): {value: string; label: string}[] {
    return Object.values(IncidentDataSource).map(label => ({
        label,
        value: label
    }))
}
