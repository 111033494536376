<template>
    <div>
        <pui-navigation-toolbar
            :link-items="linkItems"
            :structure-select="{
                config: {
                    options: availableSites.map(site => {
                        return {
                            text: site.displayName,
                            id: site.siteId.toString(),
                        }
                    })
                },
                selectedId: selectedSiteId.toString(),
            }"
            :action-items="actionsList"
            @clicked:tab="handleNavigation"
            @clicked:action="openUploadModal"
            @selected:structure-select-id="onSelectSite"
        />
        <upload-dialog
            :open="isHelpCaseModalOpen"
            @close="closeUploadModal"
        />
    </div>
</template>
<script lang="ts">
import {computed, defineComponent, ref, watch} from '@vue/composition-api'
import UploadDialog from '@/components/UploadDialog.vue'
import useSiteSettings from '@/store/composables/useSiteSettings'
import useIncident from '@/store/composables/useIncident'
import useIncidentHistory from '@/store/composables/useIncidentHistory'
import {Site} from '@/models/ResponseTypes'
import { getI18n } from '@/utils/translation-manager'
import useSitePermissions from '@/store/composables/useSitePermissions'
import useUser from '@/store/composables/useUser'
import useHelpCase from '@/store/composables/useHelpCase'

export default defineComponent({
    components: {UploadDialog},
    setup(_, {root}) {
        const i18n = getI18n()
        const { isSiteAdmin, preselectedUserSite } = useSitePermissions()

        const linkItems = ref([
            {displayName: i18n.t('tabNames.incidents'), routeName: 'Incident', isActive: false, alias: ['IncidentNew', 'Root']},
            {displayName: i18n.t('tabNames.actionKpis'), routeName: 'ActionKpis', isActive: false, alias: []},
            {displayName: i18n.t('tabNames.incidentKpis'), routeName: 'IncidentKpis', isActive: false, alias: []},
        ])
        const {fetchIncidents, setSelectedIncident} = useIncident()
        const {fetchSites, selectedSiteId, setSelectedSite} = useSiteSettings()
        const {getIncidentHistory} = useIncidentHistory()
        const {getSiteUsers, getSiteInvestigators} = useUser()
        const {getHelpCases} = useHelpCase()

        watch(
            isSiteAdmin,
            () => {
                if(isSiteAdmin.value) {
                    linkItems.value.push(
                        {displayName: i18n.t('tabNames.settings'), routeName: 'Settings', isActive: false, alias: []}
                    )
                } else {
                    const settingsTabIndex = linkItems.value.findIndex(link => link.routeName === 'Settings')
                    if(settingsTabIndex > 0) {
                        linkItems.value.splice(settingsTabIndex, 1)
                    }
                }
            },
            {immediate: true}
        )

        watch(
            selectedSiteId,
            () => {
                if (selectedSiteId.value) {
                    Promise.all([
                        getSiteUsers(selectedSiteId.value),
                        getSiteInvestigators(selectedSiteId.value),
                        getHelpCases(selectedSiteId.value)
                    ])
                }
            },
            {immediate: true}
        )

        watch(() => root.$route, ({name: routeName}) => {
            linkItems.value.forEach(
                item => item.isActive = typeof routeName === 'string'
                && [item.routeName, ...item.alias].includes(routeName)
            )
            linkItems.value = [...linkItems.value]
        }, {immediate: true})

        function handleNavigation(tabItem: any): void {
            if (root.$route.name !== tabItem.routeName) {
                root.$router.push({name: tabItem.routeName})
            }
        }

        const isHelpCaseModalOpen = ref(false)

        const closeUploadModal = (): void => {
            isHelpCaseModalOpen.value = false
        }
        const openUploadModal = (): void => {
            isHelpCaseModalOpen.value = true
        }

        const availableSites = ref<Site[]>([])

        const onSelectSite = async (rawSiteId: string): Promise<void> => {
            const siteId = parseInt(rawSiteId)
            const selection = availableSites.value.find(site => site.siteId === siteId)

            if (!selection) return

            setSelectedSite(selection)
            const shouldNavigateToRoot = root.$route.name === 'Incident'
            const shouldFetchIncidents = shouldNavigateToRoot || root.$route.name === 'Root'
            if(shouldFetchIncidents) {
                fetchIncidents()
                getIncidentHistory()
                if(shouldNavigateToRoot) {
                    await root.$router.push({name: 'Root'})
                }
                setSelectedIncident(null)
            }
        }

        fetchSites().then((data) => {
            availableSites.value = data
            if (!preselectedUserSite.value)
                return
            if (preselectedUserSite.value.siteId !== selectedSiteId.value) {
                setSelectedSite(preselectedUserSite.value)
                fetchIncidents()
            }
        })

        const actionsList = computed(() => {
            const actionsList = []
            if (isSiteAdmin.value) {
                actionsList.push({actionName: 'upload', iconName: 'upload', label: i18n.t('common.upload')})
            }
            return actionsList
        })

        return {
            linkItems,
            handleNavigation,
            openUploadModal,
            isHelpCaseModalOpen,
            closeUploadModal,
            availableSites,
            onSelectSite,
            selectedSiteId,
            actionsList,
        }
    }
})
</script>
