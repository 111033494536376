import {
    IncidentHistoryCostImpactOverview,
    IncidentHistoryOpenInvestigations,
    IncidentHistoryPageResponse
} from '@/models/ResponseTypes'
import {AxiosRequestConfig} from 'axios'
import { sdk } from '@/utils/sdk'

export class IncidentHistoryService {

    public async getIncidentHistory(config?: AxiosRequestConfig): Promise<IncidentHistoryPageResponse> {
        return (await sdk?.backend.request.api.get<{result: IncidentHistoryPageResponse}>(
            `history/incidents`,
            config
        )).data.result
    }

    public async getIncidentHistoryCostImpactOverview(config?: AxiosRequestConfig): Promise<IncidentHistoryCostImpactOverview[]> {
        return (await sdk?.backend.request.api.get<{result: IncidentHistoryCostImpactOverview[]}>(
            `history/incidents/costimpact`,
            config
        )).data.result
    }

    public async getIncidentHistoryOpenInvestigationsOverview(config?: AxiosRequestConfig): Promise<IncidentHistoryOpenInvestigations[]> {
        return (await sdk?.backend.request.api.get<{result: IncidentHistoryOpenInvestigations[]}>(
            `history/incidents/opencount`,
            config
        )).data.result
    }

}


export default new IncidentHistoryService()
