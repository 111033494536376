<template>
    <pui-lightbox
        :key="refreshKey"
        :fit-content="true"
        :visible="open"
        class="help-case-upload-dialog"
        :default-header-label="$t('uploadDialog.title')"
        default-trigger-label="open"
        @puilightbox:close="close"
    >
        <div
            slot="lightbox-trigger"
        />
        <pui-form-select
            v-model="selectedUploadForm"
            class="help-case-upload-dialog-select mt-6"
            label=""
            :options="uploadFormSelectOptions"
        />
        <div
            v-if="selectedUploadForm === 'helpCaseData'"
            class="help-case-upload-dialog-content"
        >
            <upload-help-case-list-dialog @close="close" />
        </div>
        <div
            v-else
            class="help-case-upload-dialog-content"
        >
            <upload-backfill-data-dialog @close="close" />
        </div>
    </pui-lightbox>
</template>


<script lang="ts">
import {defineComponent, ref, watch} from '@vue/composition-api'
import UploadBackfillDataDialog from '@/components/UploadBackfillDataDialog.vue'
import UploadHelpCaseListDialog from '@/components/UploadHelpCaseListDialog.vue'
import { getI18n } from '@/utils/translation-manager'

export default defineComponent({
    components: {UploadHelpCaseListDialog, UploadBackfillDataDialog},
    props: {
        open: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, {emit}) {
        const i18n = getI18n()
        const selectedUploadForm = ref('helpCaseData')
        const uploadFormSelectOptions = [
            {
                label: i18n.t('uploadDialog.type.helpCaseData'),
                value: 'helpCaseData'
            },
            {
                label: i18n.t('uploadDialog.type.backfillData'),
                value: 'backfillData'
            }
        ]

        const refreshKey = ref(0)

        const close = (): void => {
            emit('close')
            refreshKey.value++
        }

        watch(() => props.open, () => {
            refreshKey.value++
        })


        return {selectedUploadForm, uploadFormSelectOptions, close, refreshKey}
    }
})
</script>
