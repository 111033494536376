import { Module } from 'vuex'
import { Application } from '@/models/application'
import { appService } from '@/services/app-service'
import { MUTATIONS } from './types/actions-and-mutations'

const useCases: Module<any, any> = {
    state: {
        useCases: [],
    },

    mutations: {
        setUseCases(state, payload: Application[]): void {
            state.useCases = payload
        },
    },

    actions: {
        loadUseCases: async ({ commit }: any): Promise<Application[]> => {
            commit('loading', true, { root: true })
            try {
                const useCases = await appService.getApps()
                commit(MUTATIONS.SET_USE_CASES, useCases)
                return useCases
            } catch(error: any) {
                return []
            } finally {
                commit('loading', false, { root: true })
            }
        },
    },

    getters: {
        useCases(state): Application[] {
            return state.useCases
        },
    },
    namespaced: true
}

export default {
    state: useCases.state,
    getters: useCases.getters,
    actions: useCases.actions,
    mutations: useCases.mutations
}
