import {AxiosRequestConfig} from 'axios'
import {NotificationRecipientRequest} from '@/models/RequestTypes'
import {Site, SiteSettings} from '@/models/ResponseTypes'
import { User } from '@/models/users-response'
import { sdk } from '@/utils/sdk'

export class SiteSettingsService {
    endpoint = 'site-settings'

    public async getSites(config?: AxiosRequestConfig): Promise<Site[]> {
        return (await sdk?.backend.request.api.get<{result: {siteReferences: Site[]}}>(
            `${this.endpoint}/sites`,
            config
        )).data.result.siteReferences
    }

    public async getSiteSettings(siteId: number, config?: AxiosRequestConfig): Promise<SiteSettings> {
        return (await sdk?.backend.request.api.get<{result: {settings: SiteSettings}}>(
            `${this.endpoint}/${siteId}`,
            config
        )).data.result.settings
    }

    public async putSiteSettings(siteId: number, settings: SiteSettings, config?: AxiosRequestConfig): Promise<void> {
        await sdk?.backend.request.api.put(
            `${this.endpoint}/${siteId}`,
            settings,
            config
        )
    }

    public async getNotificationRecipients(siteId: number, config?: AxiosRequestConfig): Promise<User[]> {
        return (await sdk?.backend.request.api.get<{result: {recipients: User[]}}>(
            `${this.endpoint}/${siteId}/notification-recipients`,
            config
        )).data.result.recipients
    }

    public async postNotificationRecipient(siteId: number, recipients: string[], config?: AxiosRequestConfig): Promise<void> {
        const recipientRequest: NotificationRecipientRequest[] = recipients.map(kid => ({
            kid,
            siteReference: siteId
        }))

        await sdk?.backend.request.api.post(
            `${this.endpoint}/${siteId}/notification-recipients`,
            recipientRequest,
            config
        )
    }

    public async deleteNotificationRecipient(siteId: number, recipients: string[], config?: AxiosRequestConfig): Promise<void> {
        const encodedRecipients = recipients.map(recipient => encodeURIComponent(recipient)).join('&Kids=')
        await sdk?.backend.request.api.delete(
            `${this.endpoint}/${siteId}/notification-recipients?Kids=${encodedRecipients}`,
            config
        )
    }
}

export default new SiteSettingsService()
