<template>
    <div class="access-forbidden">
        <div class="access-forbidden__message">
            {{ $t('forbiddenPage.message') }}
        </div>
        <pui-button
            type="button"
            @click="requestAccess"
        >
            {{ $t('forbiddenPage.requestAccess') }}
        </pui-button>
    </div>
</template>

<script lang="ts">
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
    setup() {
        const requestAccess = (): void => {
            window.open(`${process.env.VUE_APP_APPSTORE_UI_URL}/contact-page/${process.env.VUE_APP_USE_CASE_ID}`, '_blank')
        }
        return {requestAccess}
    }
})
</script>

<style lang="less" scoped>
.access-forbidden {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__message {
        font-size: 1.8rem;
        align-items: center;
        margin-bottom: 5rem;
    }
}
</style>
