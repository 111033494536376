export interface AssetPermission {
    assetSid: number;
    action: 'Read' | 'Write';
}

export class UserProfile {
    public name?: string;
    public email?: string;
    public permissions?: string[];
    public assetPermissions?: AssetPermission[];
    public kid?: string;

    public constructor(name?: string, email?: string, permissions?: string[], assetPermissions?: AssetPermission[], kid?: string) {
        this.name = name
        this.email = email
        this.permissions = permissions?.map((permissionName: string) => permissionName)
        this.assetPermissions = assetPermissions
        this.kid = kid
    }
}
