import '@/installCompositionApi.js'
import {computed, ref} from '@vue/composition-api'
import {CreateIncidentResponse, IncidentFrontend, IncidentPageResponse} from '@/models/ResponseTypes'
import {AxiosRequestConfig, CancelToken} from 'axios'
import incidentService from '@/services/IncidentService'
import {transformToBackendFormat, transformToFrontendFormat} from '@/adapters/IncidentAdapter'
import {IncidentFilter} from '@/models/IncidentFilter'
import qs from 'qs'
import IncidentSort from '@/models/IncidentSort'
import useSiteSettings from './useSiteSettings'
import {IncidentDataSource} from '@/types/IncidentDataSource'

interface StateInterface {
    incidents: IncidentFrontend[];
    incidentListRefreshKey: number;
    incidentsLoading: boolean;
    incidentsResponse: IncidentPageResponse | null;
    selectedIncident: IncidentFrontend | null;
    incidentSearchTerm: string;
    incidentFilter: IncidentFilter;
    defaultIncidentFilter: IncidentFilter;
    incidentSort: IncidentSort;
    defaultIncidentSort: IncidentSort;
}

const state = ref<StateInterface>({
    incidents: [],
    incidentListRefreshKey: 0,
    incidentsLoading: false,
    incidentsResponse: null,
    selectedIncident: null,
    incidentSearchTerm: '',
    incidentFilter: {
        dataSource: [IncidentDataSource.SAP, IncidentDataSource.MODE, IncidentDataSource.MANUAL],
        notificationStatus: [],
        investigators: [],
        eventType: [],
        conditionBefore: [],
        severityTag: ['High', 'Medium', 'Low', 'No Loss', 'No Severity'],
        eventStartTo: undefined,
        eventStartFrom: undefined,
        investigationStartTo: undefined,
        investigationStartFrom: undefined
    },
    defaultIncidentFilter: {
        dataSource: [IncidentDataSource.SAP, IncidentDataSource.MODE, IncidentDataSource.MANUAL],
        notificationStatus: [],
        investigators: [],
        eventType: [],
        conditionBefore: [],
        severityTag: ['High', 'Medium', 'Low', 'No Loss', 'No Severity'],
        eventStartTo: undefined,
        eventStartFrom: undefined,
        investigationStartTo: undefined,
        investigationStartFrom: undefined
    },
    incidentSort: {eventStart: '0'},
    defaultIncidentSort: {eventStart: '0'},
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function useIncident() {
    const {selectedSiteId} = useSiteSettings()

    const fetchIncidentByNotificationNumber = async (notificationNumber: string, requestConfig?: AxiosRequestConfig): Promise<IncidentFrontend> => {
        const response = await incidentService.getIncidentByNotificationNumber(notificationNumber, requestConfig)
        return transformToFrontendFormat(response)
    }

    const requiredRequestConfig = (requestConfig: AxiosRequestConfig | undefined): AxiosRequestConfig => {
        if (!requestConfig) {
            requestConfig = {}
            requestConfig.params = {}
        }

        const sortKey = Object.keys(state.value.incidentSort)[0] as keyof StateInterface['incidentSort']
        const sortDirection = state.value.incidentSort[sortKey]

        // if we search with a specific notificationNumber or manualIncidentId,
        // we want to find that specific incident regardless of the chosen site
        const trimmedSearchTerm = state.value.incidentSearchTerm.trim()
        const hasNotificationNumber = trimmedSearchTerm.length === 12 && !isNaN(Number(trimmedSearchTerm))
        const hasManualIncidentId = trimmedSearchTerm.length === 14 && /^\d{4}-\d{5}-OIR$/.test(trimmedSearchTerm)
        const shouldIgnoreSite = hasNotificationNumber || hasManualIncidentId

        requestConfig.params = {
            ...requestConfig.params,
            dataSource: state.value.incidentFilter.dataSource,
            notificationStatus: state.value.incidentFilter.notificationStatus,
            investigators: state.value.incidentFilter.investigators,
            eventType: state.value.incidentFilter.eventType,
            conditionBefore: state.value.incidentFilter.conditionBefore,
            severityTag: state.value.incidentFilter.severityTag,
            eventStartFrom: state.value.incidentFilter.eventStartFrom,
            eventStartTo: state.value.incidentFilter.eventStartTo,
            investigationStartFrom: state.value.incidentFilter.investigationStartFrom,
            investigationStartTo: state.value.incidentFilter.investigationStartTo,
            site: shouldIgnoreSite ? null : selectedSiteId.value,
            term: shouldIgnoreSite ? trimmedSearchTerm : state.value.incidentSearchTerm,
            sortKey: sortKey,
            page: state.value.incidentsResponse?.pageNumber ? state.value.incidentsResponse?.pageNumber + 1 : 1,
            sortDirection: sortDirection,
            size: 24
        }

        requestConfig.paramsSerializer = function (params): string {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
        return requestConfig
    }

    const fetchIncidents = async (requestConfig?: AxiosRequestConfig): Promise<IncidentFrontend[]> => {
        requestConfig = requiredRequestConfig(requestConfig)
        requestConfig.params.page = 1

        state.value.incidentsLoading = true
        state.value.incidentsResponse = await incidentService.getIncidents(requestConfig)
        state.value.incidents = state.value.incidentsResponse.items.map(transformToFrontendFormat)
        state.value.incidentsLoading = false

        return state.value.incidents
    }

    const fetchIncidentByIncidentId = async (incidentId: number, requestConfig?: AxiosRequestConfig): Promise<IncidentFrontend> => {
        const response = await incidentService.getIncidentByIncidentId(incidentId, requestConfig)
        return transformToFrontendFormat(response)
    }

    const queryIncidentsBySearchTerm = async (searchTerm: string, cancelToken?: CancelToken): Promise<IncidentFrontend[]> => {
        const requestConfig: AxiosRequestConfig = {
            params: {
                dataSource: [IncidentDataSource.SAP, IncidentDataSource.MODE, IncidentDataSource.MANUAL],
                term: searchTerm.trim(),
                site: selectedSiteId.value,
                sortKey: 'eventStart',
                sortDirection: '0',
                size: 20,
                page: 1
            },
            paramsSerializer: function (params: unknown): string {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            },
            cancelToken
        }

        const response = await incidentService.getIncidents(requestConfig)

        return response.items.map(transformToFrontendFormat)
    }

    const fetchNextIncidents = async (requestConfig?: AxiosRequestConfig): Promise<IncidentFrontend[]> => {
        const currentPage = state.value.incidentsResponse?.pageNumber || 1
        const totalPages = state.value.incidentsResponse?.totalPages || 0

        requestConfig = requiredRequestConfig(requestConfig)

        if (currentPage < totalPages) {
            state.value.incidentsResponse = await incidentService.getIncidents(requestConfig)

            state.value.incidents.push(...state.value.incidentsResponse.items.map(transformToFrontendFormat))
        }
        return state.value.incidents
    }

    const setSelectedIncidentByIncidentId = (incidentId: number): void => {
        const incident = state.value.incidents?.find(n => n.incidentId === incidentId)
        state.value.selectedIncident = incident ? incident : null
    }

    const setSelectedIncident = (incident: IncidentFrontend | null): void => {
        state.value.selectedIncident = incident
    }

    const saveIncidentToStore = async (incident: IncidentFrontend): Promise<void> => {
        await incidentService.putIncident(transformToBackendFormat(incident))
        const updatedIncident = transformToFrontendFormat(await incidentService.getIncidentByIncidentId(incident.incidentId))
        const incidentIndex = state.value.incidents?.findIndex(n => n.incidentId === updatedIncident.incidentId)
        const incidentIndexFound = incidentIndex != -1 && incidentIndex != undefined
        if (state.value.incidents != null && incidentIndexFound) {
            state.value.incidents[incidentIndex] = updatedIncident
            setSelectedIncidentByIncidentId(updatedIncident.incidentId)
        } else {
            setSelectedIncident(updatedIncident)
        }
    }

    const createIncident = (incident: IncidentFrontend): Promise<CreateIncidentResponse> => {
        const request = transformToBackendFormat(incident)

        request.incidentId = 0
        request.notificationNumber = ''

        return incidentService.postIncident(request)
    }

    const setIncidents = (incidents: IncidentFrontend[]): void => {
        state.value.incidents = incidents
    }

    const setIncidentsResponse = (incidentsPageResponse: IncidentPageResponse): void => {
        state.value.incidentsResponse = incidentsPageResponse
        state.value.incidents = incidentsPageResponse.items.map(transformToFrontendFormat)
    }

    const setSearchTerm = (searchTerm: string): string => {
        state.value.incidentSearchTerm = searchTerm
        return state.value.incidentSearchTerm
    }

    const setFilter = (filter: IncidentFilter): IncidentFilter => {
        state.value.incidentFilter = filter
        return state.value.incidentFilter
    }

    const setSorting = (sort: IncidentSort): IncidentSort => {
        state.value.incidentSort = sort
        return state.value.incidentSort
    }

    const setIncidentsLoading = (isLoading: boolean): void => {
        state.value.incidentsLoading = isLoading
    }

    return {
        createIncident,
        fetchIncidentByIncidentId,
        fetchIncidentByNotificationNumber,
        fetchIncidents,
        fetchNextIncidents,
        setSelectedIncident,
        setSelectedIncidentByIncidentId,
        saveIncidentToStore,
        setSearchTerm,
        setFilter,
        setSorting,
        setIncidentsLoading,
        setIncidents,
        setIncidentsResponse,
        queryIncidentsBySearchTerm,
        incidents: computed(() => state.value.incidents),
        incidentListRefreshKey: computed(() => state.value.incidentListRefreshKey),
        incidentsLoading: computed(() => state.value.incidentsLoading),
        selectedIncident: computed(() => state.value.selectedIncident),
        selectedIncidentId: computed(() => state.value.selectedIncident?.incidentId),
        incidentId: computed(() => state.value.selectedIncident?.incidentId),
        incidentSearchTerm: computed(() => state.value.incidentSearchTerm),
        incidentFilter: computed(() => state.value.incidentFilter),
        isFilterSelected: computed(() => JSON.stringify(state.value.incidentFilter) !== JSON.stringify(state.value.defaultIncidentFilter)),
        defaultIncidentFilter: state.value.defaultIncidentFilter,
        incidentSort: computed(() => state.value.incidentSort),
        isSortSelected: computed(() => JSON.stringify(state.value.incidentSort) !== JSON.stringify(state.value.defaultIncidentSort)),
        defaultIncidentSort: computed(() => state.value.defaultIncidentSort),
    }
}
