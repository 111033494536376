import { Module } from 'vuex'
import { UserProfile } from '@/models/UserProfile'
import { MUTATIONS } from './types/actions-and-mutations'
import useUser from './composables/useUser'
import { sdk } from '@/utils/sdk'

const user: Module<any, any> = {
    state: {
        user: new UserProfile(),
    },

    mutations: {
        setUserProfile(state, payload: UserProfile): void {
            state.user = payload
            useUser().setCurrentUser(payload)
        },
    },

    actions: {
        setUserProfileData: ({ commit }: any): void => {
            const userData: any = sdk?.localStorage.getPermissionsLocalStorage()
            if (userData) {
                const permissions = userData.permissions?.map((permission: any) => permission.name)
                const user = new UserProfile(
                    userData?.name,
                    userData?.email,
                    permissions,
                    userData.assetPermissions,
                    userData?.kid
                )
                commit(MUTATIONS.SET_USER_PROFILE, user)
            }
        },
    },

    getters: {
        user(state): UserProfile {
            return state.user
        },
        hasAppReadAccess(state): boolean {
            return state.user?.permissions?.includes('FracasRead') || false
        }
    },
    namespaced: true
}

export default {
    state: user.state,
    getters: user.getters,
    actions: user.actions,
    mutations: user.mutations
}
