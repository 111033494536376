import '@/installCompositionApi.js'
import {computed, ref} from '@vue/composition-api'
import {
    IncidentFrontend,
    IncidentHistoryCostImpactOverview,
    IncidentHistoryOpenInvestigations,
    IncidentHistoryPageResponse,
    IncidentKpiData,
    IncidentPageResponse
} from '@/models/ResponseTypes'
import {AxiosRequestConfig} from 'axios'
import qs from 'qs'
import {IncidentHistoryFilter} from '@/models/IncidentHistoryFilter'
import {transformToFrontendFormat} from '@/adapters/IncidentHistoryAdapter'
import IncidentKpiService from '@/services/IncidentKpiService'
import IncidentHistoryService from '@/services/IncidentHistoryService'
import IncidentService from '@/services/IncidentService'
import {transformToBackendFormat} from '@/adapters/IncidentAdapter'
import useSiteSettings from './useSiteSettings'
import { getWeekNumber } from '@/utils/getWeeks'

interface StateInterface {
    incidentHistoryResponse: IncidentHistoryPageResponse;
    incidentHistoryCostImpactPerFaultText: IncidentHistoryCostImpactOverview[];
    incidentHistoryOpenInvestigationsCount: IncidentHistoryOpenInvestigations[];
    incidentHistoryFilter: IncidentHistoryFilter;
    defaultIncidentHistoryFilter: IncidentHistoryFilter;
    incidentKpi: IncidentKpiData;
    isLive: boolean;
    validDate: string | null;
}

const state = ref<StateInterface>({
    incidentHistoryResponse: {
        items: [],
        pageNumber: 0,
        pageSize: 0,
        totalCount: 0,
        totalPages: 0,
        hasNextPage: false,
        hasPreviousPage: false
    },
    incidentHistoryCostImpactPerFaultText: [],
    incidentHistoryOpenInvestigationsCount: [],
    incidentHistoryFilter: {
        investigators: [],
        notificationStatus: [],
        validDate: null,
    },
    defaultIncidentHistoryFilter: {
        investigators: [],
        notificationStatus: [],
        validDate: null,
    },
    incidentKpi: {
        numOverdue: 0,
        numOverdueComments: '',
    },
    isLive: true,
    validDate: null,
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function useIncidentHistory() {
    const {selectedSiteId} = useSiteSettings()

    const setFilter = (filter: IncidentHistoryFilter): void => {
        state.value.incidentHistoryFilter = filter
    }

    const setDateInclusive = (date: string | null): Date | null => {
        if (date) {
            const result = new Date(date)
            result.setDate(result.getDate() + 1)
            return result
        }

        return null
    }

    const createConfigForHistoryRequest = (config: AxiosRequestConfig | undefined): AxiosRequestConfig => {
        if (!config) {
            config = {}
            config.params = {}
        }
        config.params = {
            ...config.params,
            investigators: state.value.incidentHistoryFilter.investigators,
            notificationStatus: state.value.incidentHistoryFilter.notificationStatus,
            validDate: setDateInclusive(state.value.validDate),
            site: selectedSiteId.value
        }
        config.paramsSerializer = function (params): string {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
        return config
    }

    const getIncidentHistory = async (config?: AxiosRequestConfig): Promise<IncidentHistoryPageResponse> => {
        if (!config) {
            config = {}
            config.params = {}
        }

        config.params = {
            ...config.params,
            investigators: state.value.incidentHistoryFilter.investigators,
            notificationStatus: state.value.incidentHistoryFilter.notificationStatus,
            validDate: setDateInclusive(state.value.validDate),
            site: selectedSiteId.value,
        }

        config.paramsSerializer = function (params): string {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }

        state.value.incidentHistoryResponse = await IncidentHistoryService.getIncidentHistory(config)
        state.value.incidentHistoryResponse.items = state.value.incidentHistoryResponse.items.map(transformToFrontendFormat)
        return state.value.incidentHistoryResponse
    }

    const getIncidentHistoryCostImpactOverview = async (config?: AxiosRequestConfig): Promise<IncidentHistoryCostImpactOverview[]> => {
        config = createConfigForHistoryRequest(config)

        state.value.incidentHistoryCostImpactPerFaultText = await IncidentHistoryService.getIncidentHistoryCostImpactOverview(config)
        return state.value.incidentHistoryCostImpactPerFaultText
    }

    const getIncidentHistoryOpenInvestigationsOverview = async (config?: AxiosRequestConfig): Promise<IncidentHistoryOpenInvestigations[]> => {
        config = createConfigForHistoryRequest(config)

        state.value.incidentHistoryOpenInvestigationsCount = await IncidentHistoryService.getIncidentHistoryOpenInvestigationsOverview(config)
        return state.value.incidentHistoryOpenInvestigationsCount
    }

    const getIncidentKpi = async (config?: AxiosRequestConfig): Promise<IncidentKpiData> => {
        config = createConfigForHistoryRequest(config)

        const response = await IncidentKpiService.getIncidentKpi(config)
        state.value.incidentKpi = response.incidentKPI
        return state.value.incidentKpi
    }

    const saveIncidentKpisComments = async (incidentKpi: IncidentKpiData): Promise<void> => {
        const incidentKpiResponse = await IncidentKpiService.putIncidentKpi(incidentKpi, selectedSiteId.value)
        state.value.incidentKpi = incidentKpiResponse.incidentKPI
    }

    const setValidDate = (validDate: string | null): void => {
        state.value.validDate = validDate
        if (validDate) {
            state.value.isLive = false
        }
    }

    const setIsLive = (isLive: boolean): void => {
        state.value.isLive = isLive
        if (isLive) {
            state.value.validDate = null
        }
    }

    const putIncident = (incident: IncidentFrontend): Promise<IncidentPageResponse> => {
        return IncidentService.putIncident(transformToBackendFormat(incident))
    }

    const getIncidentWeeks = (actionInvestigationEnd: string | null | undefined): string=> {
        const userAction = state.value.incidentHistoryOpenInvestigationsCount?.find(action => action.investigationEnd === actionInvestigationEnd)
        return getWeekNumber(userAction?.investigationEnd).toString()
    }

    const uniqueIncidentCounts: { [week: string]: number } = {}

    const getIncidentCount = (): any => {
        const investigatorActions = state.value.incidentHistoryOpenInvestigationsCount
        if (investigatorActions) {
            investigatorActions.forEach(x => {
                const week = getWeekNumber(x.investigationEnd)
                uniqueIncidentCounts[week] = (uniqueIncidentCounts[week] || 0) + x.incidentCount
            })
        }
        return Array.from(Object.values(uniqueIncidentCounts))   
    }
    
    return {
        getIncidentWeeks,
        getIncidentHistory,
        putIncident,
        getIncidentCount,
        setFilter,
        setValidDate,
        getIncidentHistoryCostImpactOverview,
        getIncidentHistoryOpenInvestigationsOverview,
        getIncidentKpi,
        saveIncidentKpisComments,
        setIsLive,
        incidentHistoryResponse: computed(() => state.value.incidentHistoryResponse),
        incidentCount: computed(() => state.value.incidentHistoryResponse.totalCount),
        incidentHistoryFilter: computed(() => state.value.incidentHistoryFilter),
        defaultIncidentHistoryFilter: state.value.defaultIncidentHistoryFilter,
        incidentKpi: computed(() => state.value.incidentKpi),
        incidentHistoryCostImpactPerFaultText: computed(() => state.value.incidentHistoryCostImpactPerFaultText),
        incidentHistoryOpenInvestigationsCount: computed(() => state.value.incidentHistoryOpenInvestigationsCount),
        isLive: computed({
            get(): boolean {
                return state.value.isLive
            },
            set(value: boolean) {
                setIsLive(value)
            }
        }),
        validDate: computed({
            get(): string | null {
                return state.value.validDate
            },
            set(value: string | null) {
                setValidDate(value)
            }
        })
    }
}
