<template>
    <div
        class="help-case-upload-dialog-content"
    >
        <pui-form
            v-if="!loading"
            @reset="onReset"
            @submit.prevent="onSubmit"
        >
            <div class="grid grid-cols-2">
                <pui-form-group
                    :label="$t('uploadDialog.chooseUploadFile')"
                    style="height: 92px"
                >
                    <pui-file-upload
                        :on-change-callback="onChangeCallback"
                        :label="$t('common.fileUpload')"
                        @puifileupload:removed="onRemove"
                    >
                        <pui-icon
                            slot="left"
                            icon-name="icon-add-attachment"
                        />
                    </pui-file-upload>
                </pui-form-group>

                <div
                    v-if="showResponseIcon"
                    class="flex items-end justify-start"
                >
                    <pui-icon
                        v-if="lastUploadSuccessful"
                        icon-color="green"
                        icon-name="check"
                        size="5rem"
                    />
                    <pui-icon
                        v-else
                        icon-color="red"
                        icon-name="close"
                        size="5rem"
                    />
                </div>
            </div>

            <pui-form-group :label="$t('uploadDialog.lastUpload')">
                <div
                    v-if="lastUploadSuccessful"
                    class="flex flex-row"
                    style="font-size: 1.4rem; padding-left:1.2rem "
                >
                    {{ lastUpload.fileName }} {{ lastUpload.date }}
                </div>
                <div
                    v-else
                    class="flex flex-row"
                    style="font-size: 1.4rem; padding-left:1.2rem "
                >
                    {{ $t('uploadDialog.noValidFile') }}
                </div>
            </pui-form-group>

            <div class="flex flex-row justify-end mt-16">
                <pui-button
                    class="mr-2"
                    state="secondary"
                    type="reset"
                >
                    {{ $t('common.cancel') }}
                </pui-button>
                <pui-button
                    :disabled="!formFile"
                    state="primary"
                    type="submit"
                >
                    {{ $t('common.submit') }}
                </pui-button>
            </div>
        </pui-form>
        <pui-loader-spinner
            v-else
            :message="$t('uploadDialog.uploadPendingMessage')"
            min-height="92px"
            size="60px"
            :title="$t('common.pleaseWait')"
        />
    </div>
</template>

<script lang="ts">
import {defineComponent, ref} from '@vue/composition-api'
import HelpCaseService from '@/services/HelpCaseService'
import useHelpCase from '@/store/composables/useHelpCase'
import useSiteSettings from '@/store/composables/useSiteSettings'

export default defineComponent({
    props: {
        open: {
            type: Boolean,
            default: false,
        },
    },

    setup(props, {emit}) {
        const helpCaseStore = useHelpCase()
        const { selectedSiteId } = useSiteSettings()

        const formFile = ref<File | null>(null)
        const lastUpload = ref<{ fileName: string; date: string }>({
            fileName: '',
            date: '',
        })

        const loading = ref(false)

        const lastUploadSuccessful = ref(false)

        const showResponseIcon = ref(false)

        const getLastImportedFileInfo = async (): Promise<void> => {
            const importedFileInfo = await HelpCaseService.getLastUpdatedFileInfo()
            if (importedFileInfo.lastImportedDateTime != null) {
                const uploadedDateTime = new Date(importedFileInfo.lastImportedDateTime).toLocaleDateString('en-GB') + ' - (' + new Date(importedFileInfo.lastImportedDateTime).toLocaleTimeString('en-GB').substring(0, 5) + ')'
                lastUpload.value.fileName = importedFileInfo.fileName
                lastUpload.value.date = uploadedDateTime
                lastUploadSuccessful.value = true
            }
        }

        getLastImportedFileInfo()

        const onChangeCallback = (file: File): void => {
            formFile.value = file
            showResponseIcon.value = false
        }

        const onReset = (): void => {
            formFile.value = null
            emit('close')
        }

        const onRemove = (): void => {
            formFile.value = null
        }

        const onSubmit = async (): Promise<void> => {
            const formData = new FormData()
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            formData.append('ExcelFileToUpload', formFile?.value!)
            loading.value = true
            try {
                const fileResponse = await HelpCaseService.postHelpCaseFile(formData, selectedSiteId.value)
                const uploadedDateTime = new Date(fileResponse.lastImportedDateTime).toLocaleDateString('en-GB') + ' - (' + new Date(fileResponse.lastImportedDateTime).toLocaleTimeString('en-GB').substring(0, 5) + ')'
                lastUpload.value.fileName = fileResponse.fileName
                lastUpload.value.date = uploadedDateTime
                lastUploadSuccessful.value = true
                await helpCaseStore.getHelpCases(selectedSiteId.value)
            } catch (e) {
                lastUploadSuccessful.value = false
            } finally {
                showResponseIcon.value = true
                loading.value = false
                formFile.value = null
            }
        }


        return {
            onChangeCallback,
            onSubmit,
            onReset,
            formFile,
            onRemove,
            lastUpload,
            loading,
            lastUploadSuccessful,
            showResponseIcon,
        }
    },
})
</script>
