import {IncidentKpiData, IncidentKpiResponse} from '@/models/ResponseTypes'
import {AxiosRequestConfig} from 'axios'
import {sdk} from '@/utils/sdk'

export class IncidentKpiService {

    public async getIncidentKpi(config?: AxiosRequestConfig): Promise<IncidentKpiResponse> {
        return (await sdk?.backend.request.api.get<{result: IncidentKpiResponse}>(
            `incident-kpis`,
            config
        )).data.result
    }

    public async putIncidentKpi(incidentKpi: IncidentKpiData, siteId: number): Promise<IncidentKpiResponse> {
        return (await sdk?.backend.request.api.put<{result: IncidentKpiResponse}>(
            `incident-kpis/${siteId}`,
            incidentKpi
        )).data.result
    }
}


export default new IncidentKpiService()
