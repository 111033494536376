import { CoodeSdkOptions, Environments, CoodeSdk } from '@coode/fe-sdk'

const coodeSdkPluginConfiguration: CoodeSdkOptions = {
    environment: process.env.VUE_APP_ENVIRONMENT as Environments,
    auth: {
        backEndBaseUrl: process.env.VUE_APP_API_BASE_URL,
        backEndScope: process.env.VUE_APP_FRACAS_SCOPE,
        msal: {
            auth: {
                clientId: process.env.VUE_APP_CLIENT_ID || '',
                authority: process.env.VUE_APP_AUTHORITY_URL,
                redirectUri: window.location.origin,
                postLogoutRedirectUri: `${window.location.origin}/authorization/logged-out.html`,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            },
            system: {
                loggerOptions: 0 as any,
            },
        },
    },
    insights: {
        appInsightsConnectionString: process.env.VUE_APP_APP_INSIGHTS_CONNECTION_STRING,
    }
}

export const sdk = new CoodeSdk(coodeSdkPluginConfiguration)
