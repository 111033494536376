import Vue from 'vue'
import { TranslationsManager } from '@translations/translation-plugin'
import VueI18n from 'vue-i18n'

export const getTranslationManager = (): TranslationsManager => {
    const translationManager = Vue.prototype.$translationManager
    if (!translationManager) {
        throw new Error('Can not get translationManager, please ensure that you initialized the plugin correctly.')
    }

    return translationManager
}

export const getI18n = (): VueI18n => {
    const translationManager = getTranslationManager()
    return translationManager.vueI18n
}