import Vue, { PluginObject } from 'vue'
import App from './App.vue'
import {createRouter} from './router'
import store from './store'
import 'adam.ui-core/dist/umd'
import 'adam.ui-core/utils/scaling.css'
import './registerServiceWorker'
import {VNode} from 'vue/types/umd'
import 'vue-croppa/dist/vue-croppa.css'
/* @ts-expect-error: missing types for vue-croppa */
import Croppa from 'vue-croppa'
import PebbleUI from '@enerlytics/pebble-ui'
import '@/installCompositionApi.js'
import VueRouter from 'vue-router'
import VueApexCharts from 'vue-apexcharts'
import { sdk } from '@/utils/sdk'
import TranslationPlugin, { TranslationPluginOptions } from '@translations/translation-plugin'
import { getI18n } from '@/utils/translation-manager'

Vue.use(TranslationPlugin, {
    // useCaseClientId - has to be the same as clientId used for communication with the Identity Server. Typically you can find it in the app-security.ts file
    useCaseClientId: 'coode_fracas', 
    // phraseProjectId - has to be valid project id created in Phrase.com
    phraseProjectId: process.env.VUE_APP_PHRASE_PROJECT_ID,
    //enableDebugLogging: true //optional parameter for troubleshooting
} as TranslationPluginOptions)

const i18n = getI18n()

Vue.config.productionTip = false
Vue.use(Croppa)
Vue.use(VueRouter)

/* @ts-expect-error: PebbleUI */
Vue.use(PebbleUI, {
    lang: i18n.locale,
    dateTimeLocale: 'en-GB'
})

Vue.use(VueApexCharts)

Vue.component('Apexchart', VueApexCharts)

const router = createRouter()

sdk?.auth.init().then(() => {
    new Vue({
        i18n,
        router,
        store,
        render: (h): VNode => h(App),
    }).$mount('#app')
})
