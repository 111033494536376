import { Application } from '@/models/application'
import { sdk } from '@/utils/sdk'

class AppService {
    public async getApps(): Promise<Application[]> {
        try {
            return (await sdk?.core.metaData.request.api.get<{result: {items: Application[]}}>(
                '/use-cases'
            )).data.result.items
        } catch (error) {
            return []
        }
    }
}

export const appService = new AppService()
