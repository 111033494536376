import VueRouter, { RouteConfig } from 'vue-router'

export const createRoutes = (): RouteConfig[] => {
    return [
        {
            path: '/',
            name: 'Root',
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            component: () => import(/* webpackChunkName: "Incident" */ '@/views/Incident.vue'),
        },
        {
            path: '/actionKpis',
            name: 'ActionKpis',
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            component: () => import(/* webpackChunkName: "ActionKpis" */ '@/views/ActionKpis.vue'),
        },
        {
            path: '/incidentKpis',
            name: 'IncidentKpis',
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            component: () => import(/* webpackChunkName: "IncidentKpis" */ '@/views/IncidentKpis.vue'),
        },
        {
            path: '/settings',
            name: 'Settings',
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            component: () => import(/* webpackChunkName: "Settings" */ '@/views/Settings.vue'),
        },
        {
            path: '/forbidden',
            name: 'Forbidden',
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            component: () => import(/* webpackChunkName: "Forbidden" */ '@/views/Forbidden.vue'),
        },
        {
            path: '/new',
            name: 'IncidentNew',
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            component: () => import(/* webpackChunkName: "Incident" */ '@/views/Incident.vue'),
        },
        {
            // register this route last, because order matters and this route will match everything
            path: '/:incidentId?',
            name: 'Incident',
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            component: () => import(/* webpackChunkName: "Incident" */ '@/views/Incident.vue'),
            props: true
        },
        {
            path: '/maintenance',
            name: 'Maintenance',
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            component: () => import(/* webpackChunkName: "Maintenance" */ '@/views/Maintenance.vue'),
        },
    ]
}

export const createRouter = (): VueRouter => {
    const router = new VueRouter({
        routes: createRoutes(),
    })
  
    router.beforeEach((to, from, next) => {
        const maintenanceMode = process.env.VUE_APP_MAINTENANCE_MODE === 'true'
        if (maintenanceMode && to.name !== 'Maintenance') {
            next({ name: 'Maintenance' })
        } else {
            next()
        }
    })

    return router
}