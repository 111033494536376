import '@/installCompositionApi.js'
import {computed, ref} from '@vue/composition-api'
import siteSettingsService from '@/services/SiteSettingsService'
import {Site, SiteSettings} from '@/models/ResponseTypes'
import { User } from '@/models/users-response'

interface StateInterface {
    selectedSite: Site | undefined;
    selectedSiteSettings: SiteSettings | undefined;
    recipients: readonly User[];
    availableSites: Site[];
}

const state = ref<StateInterface>({
    selectedSite: undefined,
    selectedSiteSettings: undefined,
    recipients: [],
    availableSites: [],
})
const DEFAULT_SITE_ID = 4085 // Enfield

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function useSiteSettings() {
    const fetchSiteSettings = async (siteId: number): Promise<SiteSettings> => {
        const settings = await siteSettingsService.getSiteSettings(siteId)

        settings.unitSettings?.forEach(unit => {
            unit.costPerUnitPenalty = unit.costPerUnitPenalty.toString()
        })

        state.value.selectedSiteSettings = settings
        return settings
    }

    const setSelectedSite = async (site: Site): Promise<void> => {
        state.value.selectedSite = site
        await fetchSiteSettings(site.siteId)
    }

    const fetchSites = async (siteId?: number): Promise<Site[]> => {
        const sites = await siteSettingsService.getSites()
        state.value.availableSites = sites

        siteId = siteId ?? DEFAULT_SITE_ID

        if(!state.value.selectedSite && state.value.availableSites.length > 0) {
            setSelectedSite(state.value.availableSites.find(site => site.siteId === siteId) ?? state.value.availableSites[0])
        }
        return state.value.availableSites
    }

    const setSelectedSiteId = async (siteId?: number): Promise<void> => {
        if(!siteId || state.value.selectedSite?.siteId === siteId) {
            return
        }
        if(state.value.availableSites.length === 0) {
            await fetchSites(siteId)
        }

        else {
            const site = state.value.availableSites.find(site => site.siteId === siteId)
            if(site && site.siteId !== state.value.selectedSite?.siteId) {
                setSelectedSite(site)
            }
        }
    }

    const saveSettings = async (siteId: number, settings?: SiteSettings): Promise<void> => {
        if (!settings) {
            return
        }
        return siteSettingsService.putSiteSettings(siteId, settings)
    }

    const fetchNotificationRecipients = async (siteId: number): Promise<User[]> => {
        const recipients = await siteSettingsService.getNotificationRecipients(siteId)

        state.value.recipients = recipients

        return recipients
    }

    const addNotificationRecipient = async (siteId: number, recipients: string[]): Promise<void> => {
        return siteSettingsService.postNotificationRecipient(siteId, recipients)
    }

    const removeNotificationRecipient = async (siteId: number, recipients: string[]): Promise<void> => {
        return siteSettingsService.deleteNotificationRecipient(siteId, recipients)
    }

    return {
        fetchSites,
        saveSettings,
        setSelectedSite,
        setSelectedSiteId,
        fetchNotificationRecipients,
        addNotificationRecipient,
        removeNotificationRecipient,
        availableSites: computed(() => state.value.availableSites),
        recipients: computed(() => state.value.recipients),
        selectedSite: computed(() => state.value.selectedSite),
        selectedSiteId: computed(() => state.value.selectedSite?.siteId ?? DEFAULT_SITE_ID),
        selectedSiteName: computed(() => state.value.selectedSite?.displayName ?? ''),
        selectedSiteSettings: computed(() => state.value.selectedSiteSettings),
        DEFAULT_SITE_ID
    }
}
